import React, { useState } from 'react'
import { Container, Input, Button, FormText, Row, Card } from 'reactstrap'
import { navigate, Link } from 'gatsby'
import axios from 'axios'
import OtpInput from 'react-otp-input'

import './index.scss'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import BackgroundImg from '../../assets/graphics/graphic-login-background.svg'
import LoginImg from '../../assets/graphics/graphic-karate-man.svg'

const RegisterPage = () => {
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [newsletter, setNewsletter] = useState(true)
	const [otp, setOtp] = useState('')
	const [error, setError] = useState('')

	const [userId, setUserId] = useState(0)

	const removeWhiteSpace = name => {
		return name.replace(/ /g, '')
	}

	const validateEmail = () => {
		const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (reg.test(email)) {
			return false
		} else {
			return true
		}
	}

	const handleSendOtp = async e => {
		setError('')
		e.preventDefault()

		// if we have a cpcId in localStorage, send it to the API so we can update that post with user (author) info..TS
		const cpcId = window.localStorage.getItem('cpcId') ? window.localStorage.getItem('cpcId') : null
		try {
			const data = {
				cpcId,
				name: removeWhiteSpace(name),
				email: email,
				subscribe: newsletter,
				otp,
			}
			const response = await axios.post(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/users/new`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			setUserId(response.data.userId)

			if (cpcId) {
				window.localStorage.removeItem('cpcId')
			}
		} catch (error) {
			console.error('Error registering user: ', error)
			setError('Error occurred. Please try again.')
		}
	}

	const handleJoinNow = () => {
		setError('')

		// store the userId in cookies/JWT and then navigate to profile
		window.localStorage.setItem('userId', userId)
		navigate('/profile')
	}

	const handleSetOtp = e => {
		setError('')
		setOtp(e)
		if (e.length === 4) {
			verifyCode(e)
		}
	}

	const resendCode = async e => {
		setError('')
		e.preventDefault()

		try {
			const data = {
				email: email,
				otp,
			}
			const response = await axios.post(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/users/auto`, data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})

			setUserId(response.data.userId)
		} catch (error) {
			console.log('Error logging user in: ', error)
			setError('Error occurred. Please try again.')
		}
	}

	const verifyCode = async e => {
		const data = {
			userId,
			otp: e ? e : otp,
			time: new Date(),
		}

		const response = await axios.post(`${process.env.DATABASE_ROOT_URL}${process.env.DATABASE_STAGE}/users/verify`, data, {
			headers: {
				'Content-Type': 'application/json',
			},
		})

		if (response.data.verified) {
			handleJoinNow()
		} else {
			setError(response.data.error)
		}
	}

	return (
		<Layout>
			<SEO title='Register' />
			<Container>
				<Row className='login-row'>
					<img src={BackgroundImg} alt='Background' className='login-background' />
					{userId ? (
						<Card className='login-card'>
							<h2 className='login-card-title mt-2'>Welcome</h2>
							<img src={LoginImg} alt='Login' />
							<FormText className='login-card-text'>Please enter your login code</FormText>
							<Row className='justify-content-around mt-5 mb-3'>
								<OtpInput
									inputStyle='mx-4 otp-input'
									focusStyle='otp-focus'
									numInputs={4}
									value={otp}
									onChange={handleSetOtp}
									isInputNum
									shouldAutoFocus
								/>
							</Row>
							<Row className='login-card-error mb-1'>{error}</Row>
							<Button id='join-now' disabled={otp.length != 4} onClick={handleJoinNow} className='login-card-button'>
								JOIN NOW
							</Button>
							<FormText className='login-card-text'>
								Didn&apos;t receive an email?
								<a onClick={resendCode}>&nbsp;Resend here</a>
							</FormText>
						</Card>
					) : (
						<Card className='login-card'>
							<h2 className='login-card-title mt-2'>Welcome</h2>
							<img src={LoginImg} alt='Login' />
							<FormText className='login-card-text'>We will send you a one-time code to sign in</FormText>
							<Input
								className='login-card-input'
								type='text'
								value={name}
								name='username'
								placeholder='Enter your name'
								onChange={e => setName(e.target.value)}
							/>
							<Input
								className='login-card-input'
								type='email'
								name='email'
								placeholder='Enter your email'
								onChange={e => setEmail(e.target.value)}
							/>
							<Row className='login-card-error'>{error}</Row>
							<Row className='login-card-checkbox'>
								<Input addon type='checkbox' id='newsletter' checked={newsletter} onChange={() => setNewsletter(!newsletter)} />
								<span>Send me occasional gratitude news.</span>
							</Row>

							<Button disabled={validateEmail() || !name} onClick={handleSendOtp} className='login-card-button'>
								SEND EMAIL
							</Button>
						</Card>
					)}
				</Row>
				<Row>
					<span className='login-terms-text'>
						By getting access, you agree <br /> to our&nbsp;
						<Link to='/terms' target='_blank' rel='noopener noreferrer'>
							Terms of Service
						</Link>
					</span>
				</Row>
			</Container>
		</Layout>
	)
}

export default RegisterPage
